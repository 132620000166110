import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, lastValueFrom, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EULAValues, UserProfileResponse } from '../interfaces/usersResponse';
import { handleError } from '../utils/generic-utils';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

export enum AvailableFeature {
  ContentUploadExtended = 'content_upload_extended',
  DeviceAdvancedSearch = 'device_advanced_search',
  DeviceManagement = 'device_management_hybrid_mode',
  EPG = 'epg',
  TagManagerContent = 'tag_manager_content',
  TagManagerLocations = 'tag_manager_locations',
  TagManagerSyncGroups = 'tag_manager_sync_groups',
  TizenAppV2 = 'iptv_tizen_app_v2',
  WebPlayer = 'web_player',
  DeviceQuickControls = 'device_quick_controls',
  DeviceLiveViewing = 'device_live_viewing',
  DeviceAdvanceFilters = 'device_advanced_filters',
};

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {

  endpoint: string = environment.performTVApi.url;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: 'json',
  });

  retry = 0;

  _user_profile: UserProfileResponse | undefined

  _eula_res: EULAValues;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar, private authService: MsalService,) {
    this.lastActivityTime = Date.now();
  }

  private tokenRefreshTimer = null;

  private lastActivityTime: number;

  private leftExpirationTimeToRefresh = 20 * 60 * 1000; // Refresh token 20 min before it expires

  private maxInactivityTime = 5 * 60 * 1000; // User should be active in the last 5 min to refresh token

  private minTimeToExpire = 2 * 60 * 1000; // Logout user if the token is about to expire in 2 min or less (to avoid token expiration while trying to renew it)

  private activeAccount: AccountInfo | null = null;

  onUserActivity(): void {
    // Update lastActivityTime with the current timestamp on user activity
    this.lastActivityTime = Date.now();
  }

  /** True if the [_user_profile] has not been loaded */
  get isLoadingUserProfile(): boolean {
    return this._user_profile === undefined;
  }

  get userProfile() {
    return this._user_profile;
  }

  get eulaRes() {
    return this._eula_res;
  }

  updateAccount(payload: Object){
    let api = `${this.endpoint}/user-account`;
    return this.http
      .put(api, payload, { headers: this.headers, responseType: "json" },)
      .pipe(retry(this.retry), catchError(handleError));
  }

  /** Retrieves the data for the [userProfile], make sure this is only called for route guards or during initialization */
  async getUserProfile() {
    let api = `${this.endpoint}/user-profile`;
    await lastValueFrom(
      this.http
        .get(api, { headers: this.headers, responseType: 'json' })
        .pipe(retry(this.retry))
    )
      .then((res: UserProfileResponse) => {
        this._user_profile = res;
        if(this.tokenRefreshTimer){
          clearInterval(this.tokenRefreshTimer);
        }
        this.validateAccountToRefresh();
      })
      .catch((error) => {
        if(error.status == 451){
          this._eula_res = error.error;
        } else if(error.status == 401){
          this._snackBar.open('Your session has expired. You are being redirected to the login page.', 'Ok');
        } else{
          this.openSnackBar(error.message, 'Ok');
        }
      });
  }

  validateAccountToRefresh(){
    // Check if a user is signed in
    if (this.authService.instance.getAllAccounts().length > 0) {
      // Set the active account
      this.activeAccount = this.authService.instance.getAllAccounts()[0];
      // Start the timer to check for token refresh
      this.checkTokenRefresh();
    }
  }

  private checkTokenRefresh(): void {
    if (this.tokenRefreshTimer) {
      clearInterval(this.tokenRefreshTimer);
    }

    // Create an interval to check for token refresh every 1 minute
    this.tokenRefreshTimer = setInterval(() => {
      const timeToExpire = this.getExpirationTime();
      const timeSinceLastActivity = Date.now() - this.lastActivityTime;

      // Check if the token is about to expire (20 min before it expires)
      if (timeToExpire - Date.now() <= this.leftExpirationTimeToRefresh) {
        // Check if the user has been active in the last 5 min
        if (timeSinceLastActivity <= this.maxInactivityTime) {
          // Renew the token
          this.authService.acquireTokenSilent({ scopes: ['openid'], account: this.activeAccount }).subscribe({
            next: () => {
              this.activeAccount = this.authService.instance.getAllAccounts()[0];
            },
            error: (e) => {
              console.error('Error renewing token:', e);
              // If the token cannot be renewed, logout the user
              this.authService.logout({
                postLogoutRedirectUri: `/login?session=expired`
              });
            },
          });
        }
      } else if (timeToExpire - Date.now() - this.minTimeToExpire <= 0) {
        // If the token has expired (or is about to expire in 2 minutes), logout the user
        this.authService.logout({
          postLogoutRedirectUri: `/login?session=expired`
        });
      }
    }, 60000);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  isFeatureEnabled(featureName: AvailableFeature): boolean {
    /**
     * Check if the user profile is loaded and the account features are present
     */
    if(!this._user_profile || !this._user_profile.account_features) {
      console.error(
        'Tried to check if a feature was enabled, ' +
        'but the user profile or account features are not loaded or ' +
        'has an unknown format: %o',
        this._user_profile
      );
      return false;
    }
    // Check if the feature is present and enabled
    if (this._user_profile.account_features[featureName]?.enable) {
      // Check if the feature is enabled for all domains
      if (this._user_profile.account_features[featureName].email_domain === '*') {
        return true;
      }
      // Check if the user domain is allowed to use the feature
      else if (
        this._user_profile.account_features[featureName].email_domain?.length > 0
      ) {
        const allowedDomains = this._user_profile.account_features[featureName].email_domain.split(',');
        const userEmailDomain = this._user_profile.email.split('@')[1];

        for (const domain of allowedDomains) {
          if (userEmailDomain.includes(domain)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getExpirationTime(): number {
    const exp = (this.activeAccount.idTokenClaims as any).exp
    return new Date(exp * 1000).getTime();
  }
}
